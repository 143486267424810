import styled from 'styled-components';

export const SideBarContainer = styled.aside`
  max-width: 256px;
  min-width: 256px;
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  box-shadow: ${({ theme }) => theme.boxShadow.sm};
  z-index: 10;

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding-top: ${({ theme }) => theme.spacing.sm};

    > footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: ${({ theme }) => `0 ${theme.spacing.md}`};
      margin-top: ${({ theme }) => theme.spacing.sm};
      margin-bottom: ${({ theme }) => theme.spacing.sm};

      cursor: default;

      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.onSurface};
      }
    }
  }

  > header {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    > header {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
      padding: ${({ theme }) => `0 ${theme.spacing.md}`};
      min-height: 69px;
      max-height: 69px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    transform: translateX(-300px);
    transition: transform ease-in 0.2s;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    object-fit: contain;
    object-position: left;
  }
`;
