import { FC, Fragment, useMemo } from 'react';

import {
  FiBriefcase,
  FiDollarSign,
  FiFlag,
  FiHome,
  FiShare2,
  FiShield,
  FiUser,
  FiUsers,
} from 'react-icons/fi';

import { routes } from '~/shared/constants/routes';
import { permissions as permissionsConstants } from '~/shared/constants/permissions';

import { Container } from './styles';

import { NavMenu } from './NavMenu';
import { NavItem, INavItemProps } from './NavItem';

interface ISideBarNavProps {
  permissions: Record<string, boolean>;
}

export const SideBarNav: FC<ISideBarNavProps> = ({ permissions = {} }) => {
  const navItems: Pick<
    INavItemProps,
    | 'href'
    | 'label'
    | 'icon'
    | 'shouldMatchExactHref'
    | 'comingSoon'
    | 'menu'
    | 'permissions'
  >[] = useMemo(
    () => [
      {
        href: routes.DASHBOARD,
        icon: FiHome,
        label: 'Estatísticas',
        // permissions: [permissions[permissionsConstants.DASHBOARD.VIEW]],
        comingSoon: true,
      },
      {
        href: routes.COMPANIES.LIST,
        icon: FiUsers,
        label: 'Clientes',
        permissions: [permissions[permissionsConstants.COMPANIES.LIST]],
      },
      {
        href: routes.PAYMENTS.LIST,
        icon: FiDollarSign,
        label: 'Transações',
        permissions: [permissions[permissionsConstants.PAYMENTS.LIST]],
      },
      {
        href: routes.RESALES.LIST,
        icon: FiBriefcase,
        label: 'Revendas',
        permissions: [permissions[permissionsConstants.RESALES.LIST]],
      },
      // {
      //   icon: PixOutlineIcon,
      //   label: 'Menu Pix',
      //   menu: [
      //     {
      //       href: routes.PIX.KEYS,
      //       label: 'Chaves transacionais',
      //     },
      //     {
      //       href: routes.PIX.CLAIMS,
      //       label: 'Reinvidicações e Portabilidades',
      //     },
      //     {
      //       href: routes.PIX.RECONCILIATIONS,
      //       label: 'Reconciliações',
      //     },
      //     {
      //       href: routes.PIX.INFRACTION_REPORTS,
      //       label: 'Relatos de infração',
      //     },
      //     {
      //       href: routes.PIX.REFUND_REQUESTS,
      //       label: 'Solicitações de devolução',
      //     },
      //     {
      //       href: '/pix/statistics',
      //       label: 'Estatísticas',
      //     },
      //   ],
      // },
      {
        href: routes.INTEGRATIONS,
        icon: FiShare2,
        label: 'Integrações',
        permissions: [permissions[permissionsConstants.INTEGRATIONS.LIST]],
      },
      {
        href: routes.CARD_BRANDS,
        icon: FiFlag,
        label: 'Bandeiras',
        permissions: [permissions[permissionsConstants.CARD_BRANDS.LIST]],
      },
      {
        href: routes.PERMISSIONS_GROUPS,
        icon: FiShield,
        label: 'Grupos de permissões',
        permissions: [
          permissions[permissionsConstants.PERMISSIONS_GROUPS.LIST],
        ],
      },
      {
        href: routes.USERS,
        icon: FiUser,
        label: 'Usuários',
        permissions: [permissions[permissionsConstants.USERS.LIST]],
      },
    ],
    [permissions]
  );

  return (
    <Container>
      <ul>
        {navItems.map((item) => (
          <Fragment key={`${String(item.href)}-${item.label}`}>
            {item.menu?.length > 0 ? (
              <>
                {item.menu.some(
                  (itemMenu) =>
                    (itemMenu.permissions || []).length === 0 ||
                    itemMenu.permissions.some((p) => !!p)
                ) && <NavMenu {...item} />}
              </>
            ) : (
              <>
                {((item.permissions || []).length === 0 ||
                  item.permissions.some((p) => !!p)) && <NavItem {...item} />}
              </>
            )}
          </Fragment>
        ))}
      </ul>
    </Container>
  );
};
