export const permissions = {
  DASHBOARD: {
    VIEW: 'dashboard:view',
  },
  COMPANIES: {
    LIST: 'companies:list',
    CREATE: 'companies:create',
    UPDATE: 'companies:update',
  },
  RESALES: {
    LIST: 'resales:list',
    CREATE: 'resales:create',
    UPDATE: 'resales:update',
  },
  USERS: {
    LIST: 'users:list',
    CREATE: 'users:create',
    UPDATE: 'users:update',
  },
  PERMISSIONS_GROUPS: {
    LIST: 'permissions_groups:list',
    CREATE: 'permissions_groups:create',
    UPDATE: 'permissions_groups:update',
  },
  INTEGRATIONS: {
    LIST: 'integrations:list',
    CREATE: 'integrations:create',
    UPDATE: 'integrations:update',
  },
  PAYMENT_METHODS: {
    LIST: 'payment_methods:list',
    CREATE: 'payment_methods:create',
    UPDATE: 'payment_methods:update',
  },
  PAYMENTS: {
    LIST: 'payments:list',
    DETAILS: 'payments:details',
  },
  BANKS: {
    LIST: 'banks:list',
  },
  ACQUIRERS: {
    LIST: 'acquirers:list',
  },
  CARD_BRANDS: {
    LIST: 'card_brands:list',
  },
};
