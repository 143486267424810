export const theme = {
  colors: {
    primary: '#A117BB', // '#0058FF',
    onPrimary: '#FFFFFF',
    secondary: '#6D018F', //  '#1FD59B',
    onSecondary: '#FFFFFF',
    tertiary: '#0C79B7', // '#001357',
    onTertiary: '#FFFFFF',
    surface: '#FFFFFF',
    onSurface: '#231D19',
    background: '#F4F7FC',
    onBackground: '#231D19',
    backdrop: 'rgba(0, 0, 0, 0.35)',
    disabledBackground: '#E6E9F4',
    onDisabledBackground: '#5A607F',
    danger: '#E53E3E',
    onDanger: '#FFFFFF',
    warning: '#DD6B20',
    onWarning: '#FFFFFF',
    success: '#38A169',
    onSuccess: '#231D19',
    info: '#4169e1',
    onInfo: '#FFFFFF',
    darkGray: '#999999',
    lightGray: '#DDDDDD',
    transparent: 'transparent',
    phone: '#F03260',
    email: '#1A2C4D',
    whatsapp: '#25D366',
    sms: '#36CEED',
    yellow: '#ffeb81',
    gray: {
      600: '#28272C',
      300: '#757575',
      200: '#a8a8b3',
      100: '#DDDDDD',
      50: '#f5f5f5',
    },
  },
  boxShadow: {
    sm: '0px 1px 4px #15223214',
    md: '3px 3px 15px #00000044',
  },
  spacing: {
    xxs: '4px',
    xs: '8px',
    sm: '16px',
    xsm: '20px',
    md: '24px',
    lg: '32px',
    xlg: '64px',
    none: '0px',
  },
  borderRadius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '12px',
  },
  breakpoints: {
    laptop: '1440px',
    tablet: '768px',
    mobile: '375px',
  },
};
