import { createContext, useContext, useEffect, useMemo } from 'react';

import Head from 'next/head';

import {
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { setCookie } from 'nookies';
import hasEqualValues from 'lodash/isEqual';
import { ChakraProvider } from '@chakra-ui/react';

import { cookies } from '~/shared/constants/cookies';

import { useAuth } from '~/modules/auth/hooks/useAuth';

import pagLeeSocialMediaLogo from '~/shared/assets/images/pag-lee-social-media-logo.png';
import pagLeeLogoImg from '~/shared/assets/images/pag-lee-logo.png';
import pagLeeFavicon from '~/shared/assets/images/pag-lee-favicon.ico';

import { WithChildren } from '~/shared/types/WithChildren';
import { IResaleTheme } from '~/modules/resales/interfaces/IResaleTheme';

import { theme as baseTheme } from '~/shared/styles/theme';

export type ITheme = DefaultTheme & Omit<IResaleTheme, 'colors'>;

type IThemeContextData = ITheme;

interface IThemeProviderProps {
  defaultTheme: IThemeContextData;
}

const ThemeContext = createContext({} as IThemeContextData);

const ThemeProvider: WithChildren<IThemeProviderProps> = ({
  children,
  defaultTheme = {} as ITheme,
}) => {
  const { user } = useAuth();

  const formattedTheme = useMemo(
    () => ({
      ...baseTheme,
      ...(defaultTheme || {}),
      ...(user?.resale?.theme || {}),
      colors: {
        ...baseTheme.colors,
        ...(defaultTheme?.colors || {}),
        ...((user?.resale?.theme || {}).colors || {}),
      },
      ...(user?.resale?.name && {
        title: `Portal da Revenda | ${user.resale.name}`,
      }),
    }),
    [defaultTheme, user?.resale]
  );

  useEffect(() => {
    const isSameTheme = hasEqualValues(defaultTheme, formattedTheme);
    if (isSameTheme) return;

    setCookie(null, cookies.DEFAULT_THEME, JSON.stringify(formattedTheme), {
      path: '/',
    });
  }, [defaultTheme, formattedTheme]);

  const contextData: IThemeContextData = useMemo(
    () => ({
      logo: pagLeeLogoImg.src,
      favicon: pagLeeFavicon.src,
      title: 'Portal do Administrador | PagLee',
      mediaLogo: pagLeeSocialMediaLogo.src,
      description: 'Criada pela RS Solutions com o propósito de...',
      ...formattedTheme,
    }),
    [formattedTheme]
  );

  return (
    <ThemeContext.Provider value={contextData}>
      <ChakraProvider resetCSS={false}>
        <StyledComponentsThemeProvider theme={formattedTheme}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <meta name="theme-color" content={contextData.colors.primary} />
            <meta name="description" content={contextData.description} />
            <meta property="og:image" content={contextData.socialMediaLogo} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="620" />

            <link rel="icon" type="image/x-icon" href={contextData.favicon} />

            <link rel="icon" type="image/x-icon" href={contextData.favicon} />

            <title>{contextData.title}</title>
          </Head>

          {children}
        </StyledComponentsThemeProvider>
      </ChakraProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = (): IThemeContextData => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
