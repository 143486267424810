import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface IContainerProps {
  isActive: boolean;
  isDisabled: boolean;
  isSubMenu?: boolean;
  iconStrokeWidth: number;
  iconSize: number;
}

export const Container = styled.div<IContainerProps>`
  list-style: none;
  width: 100%;

  a,
  button {
    ${({ isSubMenu }) =>
      isSubMenu &&
      css`
        span {
          padding-left: ${({ theme }) => theme.spacing.lg};
        }
      `}

    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: transparent;

    padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
    height: 48px;
    width: 100%;

    display: flex;
    align-items: center;

    background: ${({ theme }) => theme.colors.surface};

    transition: all ease-in 0.3s;

    svg {
      stroke-width: ${({ iconStrokeWidth }) => `${iconStrokeWidth}px`};
      min-width: ${({ iconSize }) => `${iconSize}px`};
      width: ${({ iconSize }) => `${iconSize}px`};
      min-height: ${({ iconSize }) => `${iconSize}px`};
      width: ${({ iconSize }) => `${iconSize}px`};
      color: ${({ theme }) => theme.colors.onSurface};

      transition: color ease-in 0.2s;

      &:first-child {
        margin-right: ${({ theme }) => theme.spacing.sm};
      }
    }

    span {
      flex: 1;
      font-size: 13px;
      color: ${({ theme }) => theme.colors.onSurface};
      line-height: 48px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: color ease-in 0.2s;
      text-align: left;
    }

    ${({ isActive, theme }) =>
      isActive
        ? css`
            background: ${transparentize(0.96, theme.colors.primary)};
            border-color: ${theme.colors.primary} !important;

            svg:first-child {
              color: ${theme.colors.primary};
            }

            span {
              color: ${theme.colors.primary};
            }
          `
        : css`
            &:hover {
              opacity: 0.6;
            }
          `}
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      a,
      button {
        opacity: 0.6;

        pointer-events: none;
      }
    `}
`;
