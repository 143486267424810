import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FiX } from 'react-icons/fi';

import Image from 'next/image';

import packageInfo from '~/../package.json';
import { Tooltip } from '@chakra-ui/react';

import pagLeeLogoImg from '~/shared/assets/images/pag-lee-logo.png';

import { SideBarContainer, LogoContainer } from './styles';

import { SideBarNav } from './SideBarNav';
import { ToolbarIconButton } from '../Toolbar/ToolbarIconButton';

interface ISideBarProps {
  onClose?(): void;
  permissions: Record<string, boolean>;
}

const BaseSideBar: ForwardRefRenderFunction<HTMLElement, ISideBarProps> = (
  { onClose = () => null, permissions },
  ref
) => {
  return (
    <SideBarContainer ref={ref}>
      <header>
        <LogoContainer>
          <Image height={40} width={110} alt="PagLee" src={pagLeeLogoImg.src} />
        </LogoContainer>

        <ToolbarIconButton icon={FiX} tooltip="Fechar" onClick={onClose} />
      </header>

      <div>
        <SideBarNav permissions={permissions} />

        <footer>
          <Tooltip hasArrow label="Versão do sistema" closeOnClick={false}>
            <span>{`v${packageInfo.version}`}</span>
          </Tooltip>
        </footer>
      </div>
    </SideBarContainer>
  );
};

export const SideBar = forwardRef(BaseSideBar);
