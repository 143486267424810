import Image from 'next/image';

import { envs } from '~/shared/constants/envs';

import signinBackgroundImg from '~/shared/assets/images/signin-background.png';
import { PoweredByImg } from '~/shared/assets/images/powered-by';

import { WithChildren } from '~/shared/types/WithChildren';

import {
  Container,
  LeftBox,
  RightBox,
  RightBoxContent,
  ImageContainer,
  LeftBoxPoweredByContainer,
  RightBoxPowredByContainer,
} from './styles';

export const AuthLayout: WithChildren = ({ children }) => {
  const PoweredBy = (): JSX.Element => (
    <a href={envs.RS_SOLUTIONS_SITE_URL} target="_blank" rel="noreferrer">
      <PoweredByImg />
    </a>
  );

  return (
    <Container>
      <LeftBox>
        <ImageContainer>
          <Image src={signinBackgroundImg} alt="..." priority />
        </ImageContainer>

        <LeftBoxPoweredByContainer>
          <PoweredBy />
        </LeftBoxPoweredByContainer>
      </LeftBox>

      <RightBox>
        <RightBoxContent>
          {children}

          <RightBoxPowredByContainer>
            <PoweredBy />
          </RightBoxPowredByContainer>
        </RightBoxContent>
      </RightBox>
    </Container>
  );
};
