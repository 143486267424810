import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { parseCookies, setCookie } from 'nookies';

import { routesLabels } from '~/shared/constants/routesLabels';
import { cookies } from '~/shared/constants/cookies';

import { useAuth } from '~/modules/auth/hooks/useAuth';

import { IPermissionsGroup } from '~/modules/permissionsGroups/interfaces/IPermissionsGroup';
import { WithChildren } from '../types/WithChildren';

import { useToast } from './useToast';

interface IPermissionsContextData {
  permissions: Record<string, boolean>;
}

const PermissionsContext = createContext({} as IPermissionsContextData);

const PermissionsProvider: WithChildren = ({ children }) => {
  const router = useRouter();

  const toast = useToast();
  const { user } = useAuth();

  const [defaultPermissions, setDefaultPermissions] = useState();

  useEffect(() => {
    const { [cookies.PERMISSIONS]: defaultPermissionsString } = parseCookies();

    setDefaultPermissions(JSON.parse(defaultPermissionsString || '{}'));
  }, []);

  useEffect(() => {
    if (router.query?.forbidden) {
      const alias = routesLabels[router.query.forbidden as string];

      const title = alias
        ? `Você não tem permissão para acessar a tela de ${alias}!`
        : 'Você não tem permissão para acessar essa tela!';

      const description =
        (router.query.description as string) ||
        'Entre em contato com um adiministrador.';

      toast.show({ variant: 'warning', title, description });

      router.push({ pathname: router.pathname }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const permissions = useMemo(() => {
    if (!user) return defaultPermissions;

    const userPermissions =
      (user?.permissionsGroup as IPermissionsGroup)?.permissions || [];
    const hasUserPermissions = userPermissions.length > 0;

    if (!hasUserPermissions) return {};

    const availablePermissions = userPermissions.reduce(
      (acc, userPermission) => {
        return { ...acc, [userPermission]: true };
      },
      {}
    );

    setCookie(null, cookies.PERMISSIONS, JSON.stringify(availablePermissions), {
      path: '/',
    });

    return availablePermissions;
  }, [user, defaultPermissions]);

  const contextData = useMemo(() => ({ permissions }), [permissions]);

  return (
    <PermissionsContext.Provider value={contextData}>
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermissions = (): IPermissionsContextData => {
  return useContext(PermissionsContext);
};

export { PermissionsProvider, usePermissions };
