import { ITheme, ThemeProvider } from '~/shared/hooks/useTheme';
import { SideBarProvider } from '~/shared/hooks/useSideBar';
import { PermissionsProvider } from '~/shared/hooks/usePermissions';
import { ModalProvider } from '~/shared/hooks/useModal';
import { AuthProvider } from '~/modules/auth/hooks/useAuth';

import { WithChildren } from '~/shared/types/WithChildren';
import { IUser } from '~/modules/users/interfaces/IUser';

interface IAppProviderProps {
  defaultTheme: ITheme;
  defaultUser: IUser;
}

export const AppProvider: WithChildren<IAppProviderProps> = ({
  children,
  defaultTheme,
  defaultUser,
}) => {
  return (
    <AuthProvider defaultUser={defaultUser}>
      <ThemeProvider defaultTheme={defaultTheme}>
        <PermissionsProvider>
          <ModalProvider>
            <SideBarProvider>{children}</SideBarProvider>
          </ModalProvider>
        </PermissionsProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};
