export function validateIdentityDocument(document: string): boolean {
  if (!document) return false;

  const lastDigitIsX =
    document.charAt(document.length - 1).toUpperCase() === 'X';
  const unmaskedDocument = `${document.replace(/\D/g, '')}${
    lastDigitIsX ? 'X' : ''
  }`;

  if (unmaskedDocument.length !== 9) return false;

  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += parseInt(unmaskedDocument.charAt(i), 10) * (9 - i);
  }
  const remainder = sum % 11;
  const calculatedCheckDigit = remainder === 10 ? 'X' : remainder.toString();

  if (calculatedCheckDigit === 'X') {
    return unmaskedDocument.charAt(8) === calculatedCheckDigit;
  }

  return (
    parseInt(unmaskedDocument.charAt(8), 10) ===
    parseInt(calculatedCheckDigit, 10)
  );
}
