/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @next/next/no-css-tags */
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';

import { parseCookies } from 'nookies';

import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

import { ITheme } from '~/shared/hooks/useTheme';

import { TopBar } from '~/shared/components/TopBar';
import { DefaultLayout } from '~/shared/components/DefaultLayout';
import { AuthLayout } from '~/shared/components/AuthLayout';
import { AppProvider } from '~/shared/components/AppProvider';

import { IUser } from '~/modules/users/interfaces/IUser';

import { GlobalStyle } from '~/shared/styles/global';

import '~/shared/utils/yupValidations';

interface IMyAppProps extends AppProps {
  defaultTheme: ITheme;
  defaultUser: IUser;
}

const authRoutes = [...Object.values(routes.AUTH)];

export default function MyApp({
  Component,
  pageProps,
  router,
  defaultTheme,
  defaultUser,
}: IMyAppProps): JSX.Element {
  const Layout = authRoutes.includes(
    router.pathname === '/' ? '/' : router.pathname
  )
    ? AuthLayout
    : DefaultLayout;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="msapplication-TileColor" content="#a117bb" />
        <meta name="theme-color" content="#a117bb" />
        <meta
          name="description"
          content="Criada pela RS Solutions com o propósito de..."
        />

        <title>Portal do Administrador | PagLee</title>
      </Head>

      <AppProvider defaultTheme={defaultTheme} defaultUser={defaultUser}>
        <GlobalStyle />

        <TopBar />

        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const appCookies = parseCookies(appContext.ctx);
  const defaultTheme = JSON.parse(appCookies[cookies.DEFAULT_THEME] || '{}');
  const defaultUser = JSON.parse(
    appCookies[cookies.AUTHENTICATED_USER] || '{}'
  );

  return {
    ...appProps,
    defaultTheme,
    defaultUser: defaultUser?._id ? defaultUser : null,
  } as IMyAppProps;
};
