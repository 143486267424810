export const routes = {
  AUTH: {
    SIGN_IN: '/',
    FORGOT_PASSWORD: '/forgot-password',
    SETUP: '/setup',
    PASSWORD_RECOVERY: '/password-recovery',
  },
  DASHBOARD: '/dashboard',
  USERS: '/users',
  PERMISSIONS_GROUPS: '/permissions-groups',
  COMPANIES: {
    LIST: '/companies',
    CREATE: '/companies/create',
    UPDATE: (companyId: string): string => `/companies/${companyId}`,
  },
  RESALES: {
    LIST: '/resales',
    CREATE: '/resales/create',
    UPDATE: (resaleId: string): string => `/resales/${resaleId}`,
  },
  INTEGRATIONS: '/integrations',
  PAYMENTS: {
    LIST: '/payments',
    DETAILS: (paymentId: string): string => `/payments/${paymentId}`,
  },
  PAYMENT_METHODS: '/payment-methods',
  BANKS: '/banks',
  ANTI_FRAUD: '/anti-fraud',
  SUBSCRIPTIONS: '/subscriptions',
  PIX: {
    KEYS: '/pix/keys',
    CLAIMS: '/pix/claims',
    RECONCILIATIONS: '/pix/reconciliations',
    REFUND_REQUESTS: '/pix/refund-requests',
    INFRACTION_REPORTS: '/pix/infraction-reports',
  },
  ACQUIRERS: '/acquirers',
  CARD_BRANDS: '/card-brands',
};
