import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  main {
    display: flex;
    flex-direction: column;

    flex: 1;
    overflow-x: hidden;
    background: ${({ theme }) => theme.colors.background};

    border-left: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
