import {
  CSSProperties,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  ReactNode,
} from 'react';

import { Tooltip } from '@chakra-ui/react';

import { WithChildren } from '~/shared/types/WithChildren';
import { IMarginProps } from '~/shared/interfaces/IMarginProps';

import { BadgeType, Container, Wrapper } from './styles';

export interface IBadgeProps extends IMarginProps {
  type?: BadgeType;
  style?: CSSProperties;
  tooltip?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  isDisabled?: boolean;
  readonly showTooltip?: boolean;
  readonly onMouseLeave?: () => void;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
}

export const Badge: WithChildren<IBadgeProps> = ({
  children,
  type = 'primary',
  tooltip,
  style,
  onClick,
  isDisabled,
  showTooltip = undefined,
  href,
  target,
  mt,
  mh,
  m,
  mb,
  ml,
  mr,
  mv,
}) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    onClick?.(event);
  };

  return (
    <Tooltip
      label={tooltip}
      hasArrow
      closeOnClick={false}
      isOpen={showTooltip}
      closeDelay={0.3}
    >
      <Wrapper
        style={style}
        {...(!!href && { as: 'a', href, target })}
        mt={mt}
        mh={mh}
        m={m}
        mb={mb}
        ml={ml}
        mr={mr}
        mv={mv}
      >
        <Container
          isDisabled={isDisabled}
          type={type}
          href={href}
          onClick={onClick && handleClick}
        >
          <div>{children}</div>
        </Container>
      </Wrapper>
    </Tooltip>
  );
};
