/* eslint-disable eqeqeq */
export function validatePhoneNumber(phoneNumber: string): boolean {
  let newPhoneNumber = phoneNumber.replace(/[^\d]+/g, '');

  newPhoneNumber = newPhoneNumber.replace(/_/g, '');

  if (newPhoneNumber.length !== 10 && newPhoneNumber.length !== 11) {
    return false;
  }

  if (
    // @ts-ignore
    newPhoneNumber[0] == 0 ||
    // @ts-ignore
    (newPhoneNumber.length === 11 && newPhoneNumber[2] < 5)
  ) {
    return false;
  }

  return true;
}
