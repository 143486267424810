import { permissionsRoutes } from '~/shared/constants/permissionsRoutes';

export function getAnyValidRouteWithPermission(permissions: string[]): string {
  if ((permissions || []).length === 0) {
    return undefined;
  }

  const findPermission = Object.keys(permissionsRoutes).find((permission) => {
    return permissions.find((permisisonId) => permisisonId === permission);
  });

  return findPermission && permissionsRoutes[findPermission];
}
