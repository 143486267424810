import { FC } from 'react';

import { FiUser } from 'react-icons/fi';
import { IconType } from 'react-icons';

import Image from 'next/image';

import { Container } from './styles';

import { Tooltip } from '../Tooltip';

export type AvatarNoImageType = 'first-letter' | 'icon' | 'hidden';

export interface IAvatarProps {
  size?: number;
  iconSize?: number;
  tooltip?: string;
  src?: string;
  alt: string;
  noImageIcon?: IconType;
  isRounded?: boolean;
  transparentWithPicture?: boolean;
  circular?: boolean;
}

export const Avatar: FC<IAvatarProps> = ({
  size = 36,
  iconSize = 14,
  alt = '',
  src = '',
  tooltip = '',
  noImageIcon: NoImageIcon = FiUser,
  isRounded = true,
  transparentWithPicture = false,
}) => {
  return (
    <Tooltip label={tooltip}>
      <Container
        size={size}
        iconSize={iconSize}
        isRounded={isRounded}
        transparentWithPicture={transparentWithPicture}
        hasPicture={!!src}
      >
        {src ? (
          <Image src={src} height={size} width={size} alt={alt} />
        ) : (
          <NoImageIcon />
        )}
      </Container>
    </Tooltip>
  );
};
