import { routes } from './routes';

import { permissions } from './permissions';

export const permissionsRoutes: Record<string, string> = {
  [permissions.COMPANIES.LIST]: routes.COMPANIES.LIST,
  [permissions.PAYMENTS.LIST]: routes.PAYMENTS.LIST,
  [permissions.INTEGRATIONS.LIST]: routes.INTEGRATIONS,
  [permissions.PAYMENT_METHODS.LIST]: routes.PAYMENT_METHODS,
  [permissions.PERMISSIONS_GROUPS.LIST]: routes.PERMISSIONS_GROUPS,
  [permissions.USERS.LIST]: routes.USERS,
  [permissions.BANKS.LIST]: routes.BANKS,
};
