/* eslint-disable react/no-array-index-key */
import { FC, Fragment, useState } from 'react';

import { CollapseArrow } from '~/shared/components/Collapse/CollapseArrow';
import { Collapse } from '~/shared/components/Collapse';

import { Container } from './styles';

import { NavItem, INavItemProps } from '../NavItem';

export const NavMenu: FC<INavItemProps> = ({
  icon,
  label,
  comingSoon,
  menu,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = (): void => {
    if (!comingSoon) {
      setOpen((prevState) => !prevState);
    }
  };

  return (
    <Container isActive={false} isDisabled={comingSoon}>
      <NavItem
        icon={icon}
        comingSoon={comingSoon}
        label={label}
        onClick={toggle}
        endContent={<CollapseArrow open={open} />}
      />

      <Collapse isOpen={open}>
        {menu.map((item) => {
          const shouldRenderNavItem =
            (item.permissions || []).length === 0 ||
            item.permissions.some((p) => !!p);

          return (
            <Fragment key={`${String(item.href)}-${item.label}`}>
              {shouldRenderNavItem && <NavItem isSubMenu {...item} />}
            </Fragment>
          );
        })}
      </Collapse>
    </Container>
  );
};
