import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useInsertionEffect,
} from 'react';

import { useRouter } from 'next/router';

import { parseCookies, setCookie } from 'nookies';
import { AxiosError } from 'axios';

import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

import { useToast } from '~/shared/hooks/useToast';

import { getAnyValidRouteWithPermission } from '~/shared/utils/getAnyValidRouteWithPermission';

import { setApiDefaults } from '~/shared/services/api';
import {
  signIn as apiSignIn,
  refreshToken as apiRefreshToken,
} from '~/modules/auth/services/auth';

import { WithChildren } from '~/shared/types/WithChildren';
import { IUser } from '~/modules/users/interfaces/IUser';
import { IPermissionsGroup } from '~/modules/permissionsGroups/interfaces/IPermissionsGroup';
import { ISignInCredentials } from '~/modules/auth/interfaces/ISignInCredentials';

interface IAuthProviderProps {
  defaultUser: IUser;
}
interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<IUser>;
  signOut(): void;
  isAuthenticated: boolean;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: WithChildren<IAuthProviderProps> = ({
  children,
  defaultUser = null,
}) => {
  const router = useRouter();
  const toast = useToast();

  const [user, setUser] = useState<IUser | null>(defaultUser);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const signOut = useCallback(async () => {
    setApiDefaults();

    await router.push(routes.AUTH.SIGN_IN);

    setUser(null);
  }, [router]);

  const signIn = useCallback(
    async (credentials: ISignInCredentials) => {
      try {
        const apiResponse = await apiSignIn(credentials);

        setApiDefaults(apiResponse.accessToken, apiResponse.refreshToken);

        const apiUser = apiResponse.user;

        setUser(apiUser);
        setCookie(null, cookies.AUTHENTICATED_USER, JSON.stringify(apiUser));

        const validRoute = getAnyValidRouteWithPermission(
          (apiUser?.permissionsGroup as IPermissionsGroup)?.permissions
        );

        if (!validRoute) {
          toast.show({
            variant: 'warning',
            title: `Ops, você não tem permissão para acessar o sistema!`,
            description: 'Entre em contato com um adiministrador.',
          });

          signOut();
          return;
        }

        router.push(validRoute);

        return apiUser;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          toast.show({
            title: 'Ops, credenciais inválida(s)!',
            description: 'Seu e-mail e/ou senha está(ão) incorreto(s).',
            variant: 'error',
          });
          return;
        }

        toast.show({
          title: 'Ops, não foi possível fazer o login!',
          description: 'Recarregue a página e tente novamente.',
          variant: 'error',
        });
      }
    },
    [router, signOut, toast]
  );

  useInsertionEffect(() => {
    (async () => {
      const { [cookies.AUTH_REFRESH_TOKEN]: refreshToken } = parseCookies();

      if (refreshToken) {
        try {
          const refreshTokenResponse = await apiRefreshToken(refreshToken);

          if (refreshTokenResponse) {
            setUser(refreshTokenResponse.user);
            setCookie(
              null,
              cookies.AUTHENTICATED_USER,
              JSON.stringify(refreshTokenResponse.user)
            );
            setApiDefaults(
              refreshTokenResponse.accessToken,
              refreshTokenResponse.refreshToken
            );
          } else {
            signOut();
          }
        } catch {
          signOut();
        }
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
