import { FC } from 'react';

import Image from 'next/image';

import { useTheme } from '~/shared/hooks/useTheme';
import { useAuth } from '~/modules/auth/hooks/useAuth';

import {
  Container,
  ActionsWrapper,
  LeftContent,
  LogoContainer,
} from './styles';

import { MyAccount } from './MyAccount';
import { SideBarDrawer } from '../SideBarDrawer';

export const Toolbar: FC = () => {
  const { logo } = useTheme();
  const { user } = useAuth();

  return (
    <Container>
      <div>
        <LeftContent>
          <SideBarDrawer />

          <LogoContainer>
            <Image
              height={40}
              width={110}
              alt={user?.resale?.name || 'PagLee'}
              src={logo}
            />
          </LogoContainer>
        </LeftContent>

        <ActionsWrapper>
          {/* <ToolbarIconButton
              tooltip="Mensagens - Em breve"
              icon={FiMessageCircle}
            />

            <ToolbarIconButton
              tooltip="Notificações - Em breve"
              icon={FiBell}
            /> */}

          <MyAccount />
        </ActionsWrapper>
      </div>
    </Container>
  );
};
