import { api } from '../../../shared/services/api';

import { ISignInResponse } from '../interfaces/ISignInResponse';
import { ISignInCredentials } from '../interfaces/ISignInCredentials';
import { IRefreshTokenResponse } from '../interfaces/IRefreshTokenResponse';

export async function signIn(
  credentials: ISignInCredentials
): Promise<ISignInResponse> {
  const encodedUserCredentials = Buffer.from(
    `${credentials.email}:${credentials.password}`
  ).toString('base64');

  const response = await api.post<ISignInResponse>(
    '/admin-auth/signin',
    { grantType: 'user_credentials' },
    { headers: { Authorization: `Basic ${encodedUserCredentials}` } }
  );

  return response.data;
}

export async function refreshToken(
  _refreshToken: string
): Promise<IRefreshTokenResponse> {
  const response = await api.post<IRefreshTokenResponse>(
    '/admin-auth/refresh-token',
    { refreshToken: _refreshToken }
  );

  return response.data;
}
